<template>
  <header>
    <Logo color="black" />
    <h2>{{ title }}</h2>
  </header>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

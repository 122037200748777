<template>
  <div>
    <div class="auth-links">
      <span v-for="(link, i) in visibleLinks" :key="i">
        <RouterLink :to="link.url">{{ link.title }}</RouterLink>
        <span v-if="!isLastElement(i)" class="separator">·</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    links () {
      return [
        { url: '/signin', title: this.$i18n.t('auth.signin.title') },
        { url: '/recover', title: this.$i18n.t('auth.forgotPassword') },
        { url: '/join', title: this.$i18n.t('general.signup') }
      ]
    },

    visibleLinks () {
      return this.links.filter(link => link.url !== this.$route.path)
    }
  },

  methods: {
    isLastElement (i) {
      return i === this.visibleLinks.length - 1
    }
  }
}
</script>

<style scoped>
.auth-links {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-links .separator {
  margin: 0 0.75rem;
}
</style>

<template>
  <div>
    <AuthHeader :title="$t('auth.signin.title')" />

    <Form @submit="submit">
      <Alert
        v-if="submitFailed"
        type="danger"
        :message="$t('auth.signin.error')" />

      <div v-if="isInviteRoute" class="notification is-primary push-top">
        {{ $t("auth.signin.answerInvite") }}
      </div>

      <FormField :label="$t('general.email')">
        <EmailInputField v-model="email" :disabled="isSubmitting" />
      </FormField>

      <FormField :label="$t('general.password')">
        <PasswordInputField v-model="password" :disabled="isSubmitting" />
      </FormField>

      <FormField>
        <Checkbox v-model="remember" :label="$t('auth.signin.remember')" />
      </FormField>

      <Button
        type="secondary"
        :submit="true"
        :loading="isSubmitting"
        :title="$t('general.signin')"
        fullwidth>
      </Button>
    </Form>

    <UnsupportedBrowserDialog
      v-if="showUnsupportedDialog"
      @close="showUnsupportedDialog = false">
    </UnsupportedBrowserDialog>

    <AuthFooter />
  </div>
</template>

<script>
import AuthHeader from "./AuthHeader.vue"
import AuthFooter from "./AuthFooter.vue"

import UnsupportedBrowserDialog from "@/modules/core/components/browserSupport/UnsupportedBrowserDialog.vue"

const STATUS_DEFAULT = ""
const STATUS_SUBMITTING = "submitting"
const STATUS_ERROR = "error"

export default {
  components: {
    AuthHeader,
    AuthFooter,
    UnsupportedBrowserDialog
  },

  data () {
    return {
      email: "",
      password: "",
      remember: false,
      status: STATUS_DEFAULT,
      showUnsupportedDialog: false
    }
  },

  computed: {
    isValid () {
      return this.email.length && this.password.length
    },

    isSubmitting () {
      return this.status === STATUS_SUBMITTING
    },

    submitFailed () {
      return this.status === STATUS_ERROR
    },

    isInviteRoute () {
      return (
        this.$route.fullPath.includes("invite") ||
        this.$route.fullPath.includes("invitation")
      )
    }
  },

  created () {
    this.showUnsupportedDialog = this.$utils.isInternetExplorer()
  },

  methods: {
    async submit () {
      this.status = STATUS_DEFAULT

      if (this.isValid) {
        this.status = STATUS_SUBMITTING

        try {
          await this.$store.dispatch("auth/signIn", {
            email: this.email,
            password: this.password,
            remember: this.remember
          })

          const path = this.$route.query.redirect
            ? this.$route.query.redirect
            : "/"
          this.$router.push(path)
        } catch (error) {
          this.status = STATUS_ERROR
          console.error(error)
        }
      }
    }
  }
}
</script>
